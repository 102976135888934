<template>
  <div class="es-prompt-editor">
    <div class="es-prompt-editor__label">
      <label for="es-prompt-editor">{{ label }}</label>
    </div>
    <div class="es-prompt-editor__editor">
      <md-editor placeholder="Enter System Prompt" id="es-prompt-editor" :tab-size="4" :hide-toolbar="true"
        v-model="prompt"></md-editor>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  data: () => ({
    prompt: ''
  }),
  beforeMount() {
    this.prompt = this.modelValue
  },
  emits: ['update:modelValue', 'next'],
  watch: {
    prompt: function (val) {
      this.$emit('update:modelValue', val)
    }
  }
}
</script>
<style scoped>
.es-prompt-editor {
  margin: 20px;
}

.es-prompt-editor__label {
  margin-bottom: .5em;
  font-weight: bold;
}

.es-prompt-editor__editor {
  border-radius: 10%;
}
</style>