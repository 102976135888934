<template>
  <div class="assessment-ai-tool">
    <div class="assessment-ai-tool__header">
    </div>
    <div class="assessment-ai-tool__content">
      <component :is="renderComponent.component" @next="nextUIState" v-bind="renderComponent.props"
        v-on="renderComponent.on" />
    </div>
    <div class="assessment-ai-tool__footer">
      <es-btn :disabled="previousDisabled" v-if="uiState !== 'systemPrompt'" type="button"
        @click="previousUIState">Previous</es-btn>
      <es-btn :disabled="nextDisabled" v-if="uiState !== 'sittingsResults'" type="button"
        @click="nextUIState">Next</es-btn>
      <es-btn :disabled="!canDownload" v-if="uiState === 'sittingsResults'" type="button" @click="download">{{
        downloadText }}</es-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { esPromptEditor, esSittingIds, esSittingResults } from './../../components/assessment-ai-tool'
export default {
  name: 'AssessmentAITool',
  components: {
    'es-prompt-editor': esPromptEditor,
    'es-sitting-ids': esSittingIds,
    'es-sitting-results': esSittingResults
  },
  computed: {
    ...mapGetters('assessmentAITool', ['uiState', 'renderComponent', 'nextDisabled', 'previousDisabled', 'canDownload', 'downloadText'])
  },
  methods: {
    ...mapActions('assessmentAITool', ['nextUIState', 'previousUIState', 'download', 'pollForSittingResults'])
  },
  mounted() {
    this.pollForSittingResults()
  }
}
</script>
<style scoped>
.assessment-ai-tool {
  display: flex;
  flex: 1;
  height: 100%;
  padding: 1em;
  flex-direction: column;

}

.assessment-ai-tool__header {}

.assessment-ai-tool__content {
  flex: 1;
  overflow-y: auto;
}

.assessment-ai-tool__footer {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
}

.assessment-ai-tool__footer .es-btn:last-child {
  margin-left: 1em;
}
</style>