<template>
  <div class="es-sitting-results">
    <h1>Sitting Results</h1>
    <div class="es-sitting-results__container">
      <div class="es-sitting-results__sidebar">
        <ul>
          <li v-for="sittingItem in sittingIdsWithDetails" :key="sittingItem.sittingId">
            <button :class="{ 'active': activeSittingId === sittingItem.id }" @click="selectSitting(sittingItem.id)">
              <span>{{ sittingItem.id }}</span>&nbsp;-&nbsp;<span>{{ sittingItem.status }}</span>
            </button>
          </li>
        </ul>
      </div>
      <div class="es-sitting-results__main">
        <template v-if="isError">
          <div>
            <p>{{ selectedSittingData.message }}</p>
            <es-btn @click="retry">Re-Try</es-btn>
          </div>
        </template>
        <template v-else-if="isNull">
          <div>
            <p>Please wait..</p>
            <p>Fetching sittings details </p>
          </div>
        </template>
        <template v-else-if="isData">
          <div>
            <div class="quick-actions">
              <!-- <es-btn @click="copyUserPrompt()" color="orange">Copy User prompt</es-btn> -->
              <es-btn @click="retry(activeSittingId)" color="teal">Re-try</es-btn>
            </div>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Response ID</th>
                    <th>Template Type</th>
                    <th>Question</th>
                    <th>Rubric</th>
                    <th>Speaking Text</th>
                    <th>Response</th>
                    <th>Transcription</th>
                    <th>GPT Response Score</th>
                    <th>GPT Response Rational</th>
                    <th>GPT Token Usage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sittingItem in selectedSittingData" :key="sittingItem.response_id">
                    <td>{{ sittingItem.response_id }}</td>
                    <td>{{ sittingItem.template_type }}</td>
                    <td>{{ sittingItem.question }}</td>
                    <td>{{ sittingItem.rubric }}</td>
                    <td>{{ sittingItem.speaking_text }}</td>
                    <td>
                      <template v-if="sittingItem.signed_url">
                        <es-audio-player :audioLink="sittingItem.signed_url" />
                      </template>
                      <template v-else>
                        <p>No audio to play</p>
                      </template>
                    </td>
                    <td>
                      {{ sittingItem.transcription }}
                    </td>
                    <td>
                      <template v-if="sittingItem.gpt_response">
                        <template
                          v-for="key in Object.keys(sittingItem.gpt_response.response).filter(key => key !== 'response_id' && !key.includes('_rational'))">
                          <div :key="key" style="display: flex; flex-direction: column;">
                            <div style="text-transform: capitalize; font-weight: 600;  margin-right: .5em;">{{
                              `${key.replaceAll('_', ' ')} : ` }}
                            </div>
                            <div style="font-size: 1.1rem;">{{ sittingItem.gpt_response.response[key]
                              }}</div>
                          </div>
                        </template>
                      </template>
                    </td>
                    <td>
                      <template v-if="sittingItem.gpt_response">
                        <template
                          v-for="key in Object.keys(sittingItem.gpt_response.response).filter(key => key !== 'response_id' && key.includes('_rational'))">
                          <div :key="key" style="display: flex; flex-direction: column;">
                            <div style="text-transform: capitalize; font-weight: 600;  margin-right: .5em;">{{
                              `${key.replaceAll('_', ' ')} : ` }}
                            </div>
                            <div style="font-size: 1.1rem; ">{{ sittingItem.gpt_response.response[key]
                              }}</div>
                            <hr />
                          </div>
                        </template>
                      </template>
                    </td>
                    <td>
                      <template v-if="sittingItem.gpt_response">
                        <template v-for="key in Object.keys(sittingItem.gpt_response.usage)">
                          <div :key="key" style="display: flex; flex-direction: column;">
                            <div style="text-transform: capitalize; font-weight: 600;  margin-right: .5em;">{{
                              `${key.replaceAll('_', ' ')} : ` }}
                            </div>
                            <div style="font-size: 1.1rem; ">{{ sittingItem.gpt_response.usage[key]
                              }}</div>
                          </div>
                        </template>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    sittingIds: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    activeSittingId: ''
  }),
  computed: {
    ...mapGetters('assessmentAITool', ['getSittingResults']),
    selectedSittingData() {
      return this.getSittingResults(this.activeSittingId)
    },
    isError() {
      return this.activeSittingId && this.selectedSittingData instanceof Error
    },
    isNull() {
      return this.activeSittingId && this.selectedSittingData === null
    },
    isData() {
      return this.activeSittingId && this.selectedSittingData !== null && !(this.selectedSittingData instanceof Error)
    },
    sittingIdsWithDetails() {
      return this.sittingIds.map(sittingId => ({
        id: sittingId,
        status: this.apiStatus(sittingId)
      }))
    }
  },
  beforeMount() {
    this.sittingIds.forEach(sittingId => {
      const sittingResponse = this.getSittingResults(sittingId)
      if (!sittingResponse) {
        this.fetchSpeakingAIData(sittingId)
      }
    })
  },
  methods: {
    ...mapActions('assessmentAITool', ['fetchSpeakingAIData']),
    selectSitting(sittingId) {
      this.activeSittingId = sittingId
    },
    retry() {
      this.fetchSpeakingAIData(this.activeSittingId)
    },
    apiStatus(sittingId) {
      const sittingResponse = this.getSittingResults(sittingId)
      if (sittingResponse == null) {
        return 'Busy'
      } else if (sittingResponse instanceof Error) {
        return 'Error'
      } else if (typeof sittingResponse == 'object') {
        return 'Ready'
      }
    },
    copyUserPrompt() {
      const sittingResponse = this.getSittingResults(this.activeSittingId)
      if (sittingResponse) {
        const userPrompt = sittingResponse.user_prompt
        navigator.clipboard.writeText(userPrompt)
      }
    }
  }
}
</script>
<style>
.es-sitting-results {
  /* display: flex;
    flex-direction: column;
    gap: 1em; */
}

.es-sitting-results__container {
  display: flex;
  gap: 1em;
  flex-direction: row;
}

.es-sitting-results__sidebar {
  min-width: 300px;
  margin-right: 1em;
}

.es-sitting-results__sidebar ul {
  list-style-type: none;
  padding: 0;
}

.es-sitting-results__sidebar ul li {
  margin-bottom: 0.5em;
}

.es-sitting-results__sidebar ul li button {
  padding: 0.5em;
  width: 100%;
  font-size: .8rem;
  background-color: #f0f0f0;
  transition: all 0.3s;
  border-radius: .25em;
  color: #1a1a1a;
  font-family: 'BritishCouncilSansW15-Headline';
  text-align: left;
}

.es-sitting-results__sidebar ul li button:hover {
  background-color: #e0e0e0;
}

.es-sitting-results__sidebar ul li button.active {
  background-color: #220a5a;
  color: white;
}

.es-sitting-results__main {
  flex: 1;
}

.es-sitting-results__main table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  border-top: none;
  margin-bottom: 2em;
}

.es-sitting-results__main table th {
  background-color: #f0f0f0;
  padding: 0.5em;
  border: 1px solid #ccc;
}

.es- sitting-results__main table td {
  padding: 0.5em;
  border: 1px solid #ccc;
}

.es-sitting-results__main table tbody tr:nth-child(odd) {
  background-color: #f0f0f0;
}

.es-sitting-results__main table tbody tr:nth-child(even) {
  background-color: #e0e0e0;
}

.es-sitting-results__main table tbody tr:hover {
  background-color: #d0d0d0;
}

.es-sitting-results__main table tbody tr td {
  text-align: left;
  vertical-align: top;
  padding: 0.5em;
}

.es-sitting-results__main table tbody tr td:last-child {
  /* text-align: center; */
}

.quick-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
}
</style>