<template>
    <div class="es-sitting-ids">
        <div class="es-sitting-ids__label">
            <label for="es-sitting-ids">{{ label }}</label>
        </div>
        <div class="es-sitting-ids__editor">
            <textarea placeholder="Enter sitting ids (,) separated" id="es-sitting-ids" v-model="sittingIds"></textarea>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        modelValue: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        }
    },
    data: () => ({
        sittingIds: ''
    }),
    beforeMount() {
        this.sittingIds = this.modelValue
    },
    emits: ['update:modelValue', 'next'],
    watch: {
        sittingIds: function (val) {
            this.$emit('update:modelValue', val)
        }
    }
}
</script>
<style scoped>
.es-sitting-ids {
    margin: 20px;
}

.es-sitting-ids__label {
    margin-bottom: .5em;
    font-weight: bold;
}

.es-sitting-ids__editor {
    border-radius: 10%;
}

.es-sitting-ids__editor textarea {
    width: 100%;
    height: 40vh;
    border: 1px solid #ddd;
    resize: none;
    padding: 1em;
    outline: none;
}
</style>